import { createStyles } from '@material-ui/core';

const estilos = createStyles({
  contenedor: {
    padding: '.5em'
  },
  itemLenguajes: {
    minWidth: '150px'
  },
  selectorLenguajes: {
    marginTop: '1.5em'
  }
});

export default estilos;
