import { Button, Dialog, DialogActions, DialogContent, DialogTitle, withStyles } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import Texto from '@infotrack/presentacion-componentes/texto';
import { PropsEstilosMaterialUI } from 'Infotrack@Transversales/tiposReact';
import React from 'react';
import { IDialogoGlobalProps } from '../interfaces';
import estilos from './estilos';

const DialogoGlobal: React.FunctionComponent<PropsEstilosMaterialUI<IDialogoGlobalProps>> = ({
    accionCancelar,
    accionConfirmar,
    classes,
    estado,
    mensaje,
    mostrarCancelar,
    reiniciarDialogo,
    tipoDialogo = 'Informativa',
    titulo,
}) => (
    <Dialog open={estado} onExited={reiniciarDialogo}>
        <div className={`${classes.indicadorTipoAlerta} ${classes[`alerta${tipoDialogo}`]}`}>
            {tipoDialogo === 'Advertencia' && <WarningIcon />}
            {tipoDialogo === 'Exito' && <CheckCircleIcon />}
            {tipoDialogo === 'Error' && <ErrorIcon />}
            {tipoDialogo === 'Informativa' && <InfoIcon />}
        </div>
        <DialogTitle className={classes.textosDialogo}>{titulo ? titulo : ''}</DialogTitle>
        <DialogContent className={`${classes.textosDialogo} ${classes.contenedor}`}>
            {mensaje ? mensaje : ''}
        </DialogContent>
        <DialogActions className={classes.botones}>
            {mostrarCancelar ? (
                <>
                    <Button color="default" onClick={accionCancelar}>
                        <Texto id="boton.cancelar" color="inherit" />
                    </Button>
                    <Button color="default" onClick={accionConfirmar}>
                        <Texto id="boton.continuar" color="inherit" />
                    </Button>
                </>
            ) : (
                <Button color="default" onClick={accionConfirmar}>
                    <Texto id="boton.ok" color="inherit" />
                </Button>
            )}
        </DialogActions>
    </Dialog>
);

export default withStyles(estilos)(DialogoGlobal);
