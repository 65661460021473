import agencias from '@infotrack/presentacion-modulosbase/transversales/internacionalizacion/idiomas/es_ES/agencias';
import aplicaciones from '@infotrack/presentacion-modulosbase/transversales/internacionalizacion/idiomas/es_ES/aplicaciones';
import empresas from '@infotrack/presentacion-modulosbase/transversales/internacionalizacion/idiomas/es_ES/empresas';
import perfiles from '@infotrack/presentacion-modulosbase/transversales/internacionalizacion/idiomas/es_ES/perfiles';
import permisosPerfil from '@infotrack/presentacion-modulosbase/transversales/internacionalizacion/idiomas/es_ES/permisosPerfil';
import usuariosPersona from '@infotrack/presentacion-modulosbase/transversales/internacionalizacion/idiomas/es_ES/usuariosPersona';
import general from '@infotrack/presentacion-transversales/recursos/idiomas/es_ES';
import barraLateral from './barraLateral';
import base from './base';
import validacionContrasenia from '@infotrack/presentacion-modulosbase/transversales/internacionalizacion/idiomas/es_ES/validacionContrasenia'
import catalogo from '@infotrack/presentacion-modulosbase/transversales/internacionalizacion/idiomas/es_ES/catalogo';

export default {
    ...catalogo,
    ...agencias,
    ...aplicaciones,
    ...barraLateral,
    ...base,
    ...empresas,
    ...general,
    ...perfiles,
    ...permisosPerfil,
    ...usuariosPersona,
    ...validacionContrasenia
};
