import { Avatar, Divider, Drawer, Grid, IconButton, Tooltip, withStyles } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Texto from '@infotrack/presentacion-componentes/texto';
import DialogoPerfil from 'Infotrack@Principal/baseGeneral/componentes/dialogoPerfil';
import MenuBarra from 'Infotrack@Principal/baseGeneral/componentes/menu';
import AvatarImage from 'Infotrack@Transversales/recursos/img/blank_profile.png';
import { PropsEstilosMaterialUI } from 'Infotrack@Transversales/tiposReact';
import React from 'react';
import { IBarraLateralVistaProps } from '../interfaces';
import estilos from './estilos';

const BarraLateralVista: React.FunctionComponent<PropsEstilosMaterialUI<IBarraLateralVistaProps>> = ({
    accionCerrar,
    cerrarSesion,
    classes,
    esMovil,
    estadoBarra,
    estadoDialogoPerfil,
    irAperfil,
    irAselector,
    modificarEstadoDialogoPerfil,
    redireccionar,
    temaActivo,
    usuarioInformacion,
}) => (
    <Drawer
        anchor="left"
        classes={{ paper: classes.barraLateralPapper }}
        className={classes.barraLateral}
        onClose={esMovil ? accionCerrar : undefined}
        open={estadoBarra}
        variant={esMovil ? 'temporary' : 'persistent'}
    >
        <div className={classes.contenedorBotonVolver}>
            <Tooltip title={<Texto id="barraLateral.title.logo" />}>
                <div className={classes.logo}>
                    <img
                        src={require(`Infotrack@Transversales/recursos/img/${temaActivo}_Horizontal_App.png`)}
                        onClick={redireccionar}
                    />
                </div>
            </Tooltip>
            <IconButton onClick={accionCerrar} size="medium">
                <ChevronLeftIcon />
            </IconButton>
        </div>
        <Divider className={classes.divisorBarra} />
        <Grid container justify="center">
            <Grid item>
                <Tooltip title={<Texto id="barraLateral.title.perfil" />}>
                    <div className={classes.avatar} onClick={modificarEstadoDialogoPerfil}>
                        <Avatar
                            src={usuarioInformacion && usuarioInformacion.Foto ? usuarioInformacion.Foto : AvatarImage}
                        />
                        <KeyboardArrowDownIcon color="disabled" />
                    </div>
                </Tooltip>
            </Grid>
        </Grid>
        <DialogoPerfil
            cerrarDialogo={modificarEstadoDialogoPerfil}
            cerrarSesion={cerrarSesion}
            correo={usuarioInformacion ? usuarioInformacion.CorreoElectronico : undefined}
            irAPerfil={irAperfil}
            irAselector={irAselector}
            refElem={estadoDialogoPerfil}
        />
        <Texto
            align="center"
            color="textSecondary"
            mensaje={usuarioInformacion ? usuarioInformacion.NombreCompleto : ''}
            variant="body2"
        />
        <Texto
            align="center"
            className={classes.textoPerfil}
            color="textSecondary"
            id="barraLateral.title.perfil"
            variant="caption"
        />
        <MenuBarra />
        <Texto
            align="center"
            className={classes.numeroVersion}
            color="textSecondary"
            mensaje={process.env.REACT_APP_VERSION_APP}
            variant="caption"
        />
    </Drawer>
);

export default withStyles(estilos)(BarraLateralVista);
