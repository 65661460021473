import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction, compose, Dispatch } from 'redux';
import { TipoNotificacion } from '@infotrack/presentacion-transversales/interfacesComunes';

import notificacionGlobal from '@infotrack/presentacion-componentes/notificacionGlobal';
import {
    Autenticacion,
    IAccion,
    IAgencia,
    IMenu,
    IUsuarioAplicacionAgencia,
    IUsuarioInformacion,
    SeleccionAplicacion,
    TratamientoDatos
} from '@infotrack/presentacion-modulosbase';

import {
    establecerMenus,
    establecerPermisos,
    establecerUsuarioInformacion,
    seleccionarAgencia,
    seleccionarUsuarioAplicacionAgencia,
} from 'Infotrack@Acciones/accionesAutenticacion';
import { seleccionarRutaActiva, seleccionarTema } from 'Infotrack@Acciones/accionesConfiguracionGlobal';
import { seguridad, seguridadSinToken } from 'Infotrack@Modelos/conexiones';
import ContenedorAplicacion from 'Infotrack@Principal/baseGeneral/componentes/contenedorAplicacion';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import temas, { ILlavesTemas } from 'Infotrack@Transversales/constantes/temas';
import fondoLogin from 'Infotrack@Transversales/recursos/img/fondoLogin.jpg';
import logoInicio from 'Infotrack@Transversales/recursos/img/LogoInicio.png';
import { modificarTituloIconoAplicacion } from 'Infotrack@Transversales/utilitarios/funcionesGenerales';
import EmpresaFiltroCM from 'Infotrack@Modulos/seguridad/Empresas/controladorModelo/empresaFiltroCM';
import { IFiltroEmpresaAplicacion } from 'Infotrack@Modelos/empresas/entidades/IFiltroEmpresaAplicacion';

interface IEstadoAPropsContenedorPrincipal {
    agenciaActual: IAgencia | null;
    rutaActiva: string;
    usuarioInformacion: IUsuarioInformacion | null;
}

interface IAccionesAPropsContenedorPrincipal {
    establecerMenusAccion: (menus: IMenu[]) => AnyAction;
    establecerPermisosAccion: (permisos: IAccion[]) => AnyAction;
    establecerUsuarioInformacionAccion: (usuarioInformacion: IUsuarioInformacion) => AnyAction;
    seleccionarAgenciaAccion: (payload: IAgencia) => AnyAction;
    seleccionarUsuarioAplicacionAgenciaAccion: (payload: IUsuarioAplicacionAgencia) => AnyAction;
    seleccionarRutaActivaAccion: (payload: string) => AnyAction;
    seleccionarTemaAccion: (payload: keyof ILlavesTemas) => AnyAction;
}

const empresaFiltroCM = new EmpresaFiltroCM();

const ContenedorPrincipal: React.FunctionComponent<IEstadoAPropsContenedorPrincipal &
    IAccionesAPropsContenedorPrincipal &
    RouteComponentProps> = ({
        agenciaActual,
        establecerMenusAccion,
        establecerPermisosAccion,
        establecerUsuarioInformacionAccion,
        history,
        rutaActiva,
        seleccionarAgenciaAccion,
        seleccionarUsuarioAplicacionAgenciaAccion,
        seleccionarRutaActivaAccion,
        seleccionarTemaAccion,
        usuarioInformacion,
    }) => {
        const [cargaValida, setCargaValida] = React.useState(false);
        const [bloqueado, setBloqueado] = React.useState(false);
        const [idEmpresa, setIdEmpresa] = React.useState('');
        const [idAplicacion, setIdAplicacion] = React.useState('');

        React.useEffect(() => {
            if (
                usuarioInformacion &&
                !agenciaActual &&
                location.pathname !== '/seleccionAplicacion' &&
                location.pathname !== '/autenticacion' &&
                location.pathname !== '/tratamiento-datos'
            ) {
                notificacionGlobal('base.alerta.seleccionarAgencia', 3000, 'warning', true);
                history.push('/seleccionAplicacion');
            } else {
                setCargaValida(true);
            }
        });

        React.useEffect(() => {
            if (!usuarioInformacion && location.pathname === '/autenticacion') {
                consultarEmpresaAplicacionPorFiltro();
            }
        }, []);

        const url: string = window.location.hostname;
        const consultarEmpresaFiltro: IFiltroEmpresaAplicacion = {
            UrlAplicacion: url,
        };

        const consultarEmpresaAplicacionPorFiltro = async () => {
            const respuesta = await empresaFiltroCM.consultarEmpresaAplicacionPorFiltro(consultarEmpresaFiltro);
            if (respuesta && respuesta.Entidades && respuesta.Entidades.length > 0) {
                setIdEmpresa(respuesta.Entidades[0].IdEmpresa);
                setIdAplicacion(respuesta.Entidades[0].IdAplicacion);
            }
            if (respuesta.TipoNotificacion === TipoNotificacion.Advertencia) {
                setBloqueado(!bloqueado);
            }
        };

        const seleccionarAplicacionAgenciaCallback = (
            usuarioAplicacionAgencia: IUsuarioAplicacionAgencia,
            agencia: IAgencia,
            permisos: IAccion[],
            menus: IMenu[]
        ) => {
            seleccionarUsuarioAplicacionAgenciaAccion(usuarioAplicacionAgencia);
            seleccionarAgenciaAccion(agencia);
            establecerMenusAccion(menus);
            establecerPermisosAccion(permisos);
            seleccionarRutaActivaAccion(usuarioAplicacionAgencia.NombreAplicacion);
            seleccionarTemaAccion('SmartSuite' as keyof ILlavesTemas);
            modificarTituloIconoAplicacion('SmartSuite');
            history.push(`/${usuarioAplicacionAgencia.NombreAplicacion}`);
        };

        if (!usuarioInformacion && location.pathname !== '/autenticacion' && location.pathname !== '/tratamiento-datos') return <Redirect to="/autenticacion" />;

        if (location.pathname === '/') {
            if (usuarioInformacion) return <Redirect to="/seleccionAplicacion" />;
            else return <Redirect to="/autenticacion" />;
        }
        if (location.pathname === '/autenticacion' && usuarioInformacion) return <Redirect to="/seleccionAplicacion" />;

        if (
            location.pathname !== '/seleccionAplicacion' &&
            !location.pathname.startsWith(`/${rutaActiva}`) &&
            usuarioInformacion
        )
            return <Redirect to={`/${rutaActiva}`} />;

        return (
            <>
                <Route
                    path="/autenticacion"
                    component={() => (
                        <Autenticacion
                            autenticacionExitosaCallback={establecerUsuarioInformacionAccion}
                            fondoLogin={fondoLogin}
                            logoInicio={logoInicio}
                            seguridad={seguridadSinToken}
                            idEmpresa={idEmpresa}
                            idAplicacion={idAplicacion}
                            bloqueado={bloqueado}
                            estiloAplicacion="SmartSuite"
                        />
                    )}
                />
                <Route
                    path="/seleccionAplicacion"
                    component={() => (
                        <SeleccionAplicacion
                            agenciaActual={agenciaActual}
                            IdEmpresa={usuarioInformacion!.IdEmpresa}
                            IdUsuario={usuarioInformacion!.IdUsuario}
                            IdAplicacion={usuarioInformacion!.IdAplicacion}
                            seguridad={seguridad}
                            seleccionarAplicacionAgenciaCallback={seleccionarAplicacionAgenciaCallback}
                            Temas={temas}
                        />
                    )}
                />
                <Route
                    path="/tratamiento-datos"
                    component={() => (
                        <TratamientoDatos
                            fondoImagen={fondoLogin}
                            logo={logoInicio}
                        />
                    )}
                />
                {cargaValida && usuarioInformacion && (
                    <Route path={`/${rutaActiva}`} render={() => <ContenedorAplicacion />} />
                )}
            </>
        );
    };

const estadoAProps = (estadoGlobal: IEstadoGlobal): IEstadoAPropsContenedorPrincipal => {
    return {
        agenciaActual: estadoGlobal.estadoAutenticacion.agenciaActual,
        usuarioInformacion: estadoGlobal.estadoAutenticacion.usuarioInformacion,
        rutaActiva: estadoGlobal.estadoConfiguracion.rutaActiva,
    };
};

const accionesAProps = (dispatch: Dispatch<AnyAction>): IAccionesAPropsContenedorPrincipal => {
    return {
        establecerMenusAccion: (menus: IMenu[]) => dispatch(establecerMenus(menus)),
        establecerPermisosAccion: (permisos: IAccion[]) => dispatch(establecerPermisos(permisos)),
        establecerUsuarioInformacionAccion: (informacionUsuario: IUsuarioInformacion) =>
            dispatch(establecerUsuarioInformacion(informacionUsuario)),
        seleccionarAgenciaAccion: (agencia: IAgencia) => dispatch(seleccionarAgencia(agencia)),
        seleccionarUsuarioAplicacionAgenciaAccion: (usuarioAplicacionAgencia: IUsuarioAplicacionAgencia) =>
            dispatch(seleccionarUsuarioAplicacionAgencia(usuarioAplicacionAgencia)),
        seleccionarRutaActivaAccion: (rutaActiva: string) => dispatch(seleccionarRutaActiva(rutaActiva)),
        seleccionarTemaAccion: (tema: keyof ILlavesTemas) => dispatch(seleccionarTema(tema)),
    };
};

export default compose(connect(estadoAProps, accionesAProps), withRouter)(ContenedorPrincipal) as React.ComponentType;
