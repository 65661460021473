import AES256 from '@infotrack/utilitarios/aes256';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import { llaveGlobal } from 'Infotrack@Transversales/constantes/credenciales';

export const obtenerConfiguracionApp = (): IEstadoGlobal => {
    const aes256 = new AES256();
    const estado = sessionStorage.getItem('estadoApp');
    if (estado) {
        return JSON.parse(aes256.descifrar(estado, llaveGlobal));
    } else {
        return {
            estadoAutenticacion: {
                agenciaActual: null,
                menus: [],
                permisos: [],
                usuarioAplicacionAgenciaActual: null,
                usuarioInformacion: null,
            },
            estadoConfiguracion: {
                lenguajeActivo: 'es',
                lenguajes: ['es', 'en'],
                menuSeleccionado: 0,
                rutaActiva: 'app',
                subMenuSeleccionado: '',
                temaActivo: 'SmartSuite',
            },
        };
    }
};

export const modificarTituloIconoAplicacion = (titulo: string) => {
    window.document.title = titulo;
    const favIconAnterior = document.getElementById('favicon') as HTMLLinkElement;
    const nuevoFavIcon = document.createElement('link');
    nuevoFavIcon.id = 'favicon';
    nuevoFavIcon.rel = 'shortcut icon';
    nuevoFavIcon.href = `${titulo.replace(/ /g, '').toLowerCase()}.ico`;
    if (favIconAnterior) {
        document.head.removeChild(favIconAnterior);
    }
    document.head.appendChild(nuevoFavIcon);
};
