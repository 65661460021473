import { Theme } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

import { coloresBase } from '@infotrack/presentacion-transversales/constantes';

import { estilosMaterialUI } from '../constantesConfig';

const smartStock = createMuiTheme({
    ...estilosMaterialUI,
    palette: {
        ...estilosMaterialUI.palette,
        primary: {
            contrastText: coloresBase.paletaGeneral.blanco,
            main: coloresBase.coloresSmartSuite.SmartStock,
        },
        secondary: {
            contrastText: coloresBase.paletaGeneral.blanco,
            main: coloresBase.coloresSmartSuite.SmartStockSecundario,
        },
    },
});

const smartRoute = createMuiTheme({
    ...estilosMaterialUI,
    palette: {
        ...estilosMaterialUI.palette,
        primary: {
            contrastText: coloresBase.paletaGeneral.blanco,
            main: coloresBase.coloresSmartSuite.SmartRoute,
        },
        secondary: {
            contrastText: coloresBase.paletaGeneral.blanco,
            main: coloresBase.coloresSmartSuite.SmartRouteSecundario,
        },
    },
});

const smartWork = createMuiTheme({
    ...estilosMaterialUI,
    palette: {
        ...estilosMaterialUI.palette,
        primary: {
            contrastText: coloresBase.paletaGeneral.blanco,
            main: coloresBase.coloresSmartSuite.SmartWork,
        },
        secondary: {
            contrastText: coloresBase.paletaGeneral.blanco,
            main: coloresBase.coloresSmartSuite.SmartWorkSecundario,
        },
    },
});

const smartTruck = createMuiTheme({
    ...estilosMaterialUI,
    palette: {
        ...estilosMaterialUI.palette,
        primary: {
            contrastText: coloresBase.paletaGeneral.blanco,
            main: coloresBase.coloresSmartSuite.SmartTruck,
        },
        secondary: {
            contrastText: coloresBase.paletaGeneral.blanco,
            main: coloresBase.coloresSmartSuite.SmartTruckSecundario,
        },
    },
});

const smartIot = createMuiTheme({
    ...estilosMaterialUI,
    palette: {
        ...estilosMaterialUI.palette,
        primary: {
            contrastText: coloresBase.paletaGeneral.blanco,
            main: coloresBase.coloresSmartSuite.SmartIot,
        },
        secondary: {
            contrastText: coloresBase.paletaGeneral.blanco,
            main: coloresBase.coloresSmartSuite.SmartIotSecundario,
        },
    },
});

const smartSuite = createMuiTheme({
    ...estilosMaterialUI,
    palette: {
        ...estilosMaterialUI.palette,
        primary: {
            contrastText: coloresBase.paletaGeneral.blanco,
            main: coloresBase.coloresSmartSuite.SmartSuitePrimario,
        },
        secondary: {
            contrastText: coloresBase.paletaGeneral.blanco,
            main: coloresBase.coloresSmartSuite.SmartSuiteSecundario,
        },
    },
});

const temas = {
    SmartIot: smartIot,
    SmartRoute: smartRoute,
    SmartStock: smartStock,
    SmartSuite: smartSuite,
    SmartTruck: smartTruck,
    SmartWork: smartWork,
};

export interface ILlavesTemas {
    SmartIot: Theme;
    SmartRoute: Theme;
    SmartStock: Theme;
    SmartSuite: Theme;
    SmartTruck: Theme;
    SmartWork: Theme;
}

export default temas;
