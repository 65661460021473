import { createStyles } from '@material-ui/core';
import { paletaMaterialD } from '@infotrack/presentacion-transversales/constantes';

const estilos = createStyles({
    alertaAdvertencia: { backgroundColor: paletaMaterialD.orange_700 },
    alertaError: { backgroundColor: paletaMaterialD.red_700 },
    alertaExito: { backgroundColor: paletaMaterialD.green_600 },
    alertaInformativa: { backgroundColor: paletaMaterialD.blue_700 },
    botones: {
        justifyContent: 'space-evenly',
    },
    contenedor: {
        minWidth: '200px',
    },
    indicadorTipoAlerta: {
        '& svg': {
            color: paletaMaterialD.white,
            height: '50px',
            width: '50px',
        },
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        padding: '10px 0',
        width: '100%',
    },
    textosDialogo: {
        padding: '12px 24px',
    },
});

export default estilos;
