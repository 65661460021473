import React from 'react';

import { useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';

import DatosPersonalesModulosBase from '@infotrack/presentacion-modulosbase/modulos/DatosPersonales/DatosPersonales';

import { seguridad } from '../../../modelos/conexiones';

import { IEstadoGlobal } from '../../../reductores/interfacesReductores';

const DatosPersonales = () => {
    const history = useHistory();

    const rutaActiva = useSelector((e: IEstadoGlobal) => e.estadoConfiguracion.rutaActiva);

    const identificacion = useSelector((e: IEstadoGlobal) =>
        e.estadoAutenticacion.usuarioInformacion ? e.estadoAutenticacion.usuarioInformacion.Identificacion : ''
    );

    const idUsuario = useSelector((e: IEstadoGlobal) =>
        e.estadoAutenticacion.usuarioInformacion ? e.estadoAutenticacion.usuarioInformacion.IdUsuario : ''
    );

    const nombreAgencia = useSelector((e: IEstadoGlobal) =>
        e.estadoAutenticacion.agenciaActual ? e.estadoAutenticacion.agenciaActual.NombreAgencia : ''
    );

    return (
        <DatosPersonalesModulosBase
            cerrar={() => {
                history.push(`/${rutaActiva}`);
            }}
            identificacionPersona={identificacion}
            idUsuario={idUsuario}
            nombreAgencia={nombreAgencia}
            seguridad={seguridad}
        />
    );
};

export default DatosPersonales;
