import { useMediaQuery, useTheme } from '@material-ui/core';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import { componentesAplicacion } from 'Infotrack@Transversales/rutasModulos';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { IEstadoAPropiedades } from '../interfaces';
import ContenedorAplicacionVista from '../vista/contenedorAplicacionVista';

const ContenedorAplicacionCV: React.FunctionComponent<RouteComponentProps & IEstadoAPropiedades> = ({
  history,
  temaActivo,
  rutaActiva
}) => {
  const [estadoBarra, setEstadoBarra] = React.useState<boolean>(true);
  const [rutaBaseActiva, setRutaBaseActiva] = React.useState<boolean>(true);
  const primeraCarga = React.useRef(true);

  const theme = useTheme();
  const esMovil = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    const desactivarListener = history.listen(() => {
      validarRuta();
    });
    if (primeraCarga.current) validarRuta();
    primeraCarga.current = false;
    return () => {
      desactivarListener();
    };
  }, []);

  React.useEffect(() => {
    if (esMovil) setEstadoBarra(false);
    else setEstadoBarra(true);
  }, [esMovil]);

  const validarRuta = () => {
    if (location.pathname === `/${rutaActiva}`) setRutaBaseActiva(true);
    else setRutaBaseActiva(false);
  };

  const abrirCerrarBarra = () => {
    setEstadoBarra(!estadoBarra);
  };

  const obtenerComponentesPorAplicacionTema = () => {
    const componenteActual = componentesAplicacion.find(({ disponibleEnAplicacion }) => {
      if (disponibleEnAplicacion) {
        return typeof disponibleEnAplicacion === 'boolean'
          ? disponibleEnAplicacion
          : disponibleEnAplicacion.includes(temaActivo);
      } else return false;
    });
    if (componenteActual) return componenteActual;
    return {};
  };

  return (
    <ContenedorAplicacionVista
      accionCerrar={abrirCerrarBarra}
      accionBoton={abrirCerrarBarra}
      componenteBarra={obtenerComponentesPorAplicacionTema().componenteBarra}
      componentePrincipal={obtenerComponentesPorAplicacionTema().componentePrincipal as React.FunctionComponent}
      estadoBarra={estadoBarra}
      esMovil={esMovil}
      rutaActiva={rutaActiva}
      rutaBaseActiva={rutaBaseActiva}
    />
  );
};

const estadoAPropiedades = ({ estadoConfiguracion }: IEstadoGlobal): IEstadoAPropiedades => ({
  temaActivo: estadoConfiguracion.temaActivo,
  rutaActiva: estadoConfiguracion.rutaActiva,
});

export default connect<IEstadoAPropiedades, null, any, IEstadoGlobal>(
  estadoAPropiedades,
  null
)(withRouter(ContenedorAplicacionCV));
