import { ServiciosRest } from '@infotrack/utilitarios/servicios';
import { obtenerEstado } from 'Infotrack@Reductores/index';
import { manejadorDialogoGlobal } from '@infotrack/presentacion-utilitarios/manejadoresComponentes';

const mensajeTokenInvalido = () => {
    manejadorDialogoGlobal({
        accionConfirmar: () => {
            sessionStorage.clear();
            window.location.reload();
        },
        estado: true,
        mensaje: 'Se ha vencido su sesión, por favor vuelva a autenticarse',
        mostrarCancelar: false,
        tipoDialogo: 'Informativa',
        titulo: "Info",
    });
};

const URL_API_SEGURIDAD = process.env.REACT_APP_URL_SEGURIDAD!;
const URL_API_CATALOGO = process.env.REACT_APP_URL_CATALOGO;

const seguridadSinToken = new ServiciosRest(URL_API_SEGURIDAD, '_1nf0tr4ck*');
const seguridad = new ServiciosRest(URL_API_SEGURIDAD, '_1nf0tr4ck*', obtenerEstado, mensajeTokenInvalido);
const apiCatalogoToken = new ServiciosRest(URL_API_CATALOGO!, undefined, obtenerEstado, mensajeTokenInvalido);
export {
    seguridadSinToken,
    seguridad,
    apiCatalogoToken
}
