import { ThemeOptions } from '@material-ui/core/styles';
import { coloresBase } from '@infotrack/presentacion-transversales/constantes';

export const estilosMaterialUI: ThemeOptions = {
    overrides: {
        MuiCardContent: {
            root: {
                padding: 24,
            },
        },
        MuiDialogContent: {
            root: {
                padding: 24,
            },
        },
        MuiOutlinedInput: {
            adornedEnd: {
                paddingRight: 0,
            },
        },
        MuiDialog: {
            paper: {
                overflowX: 'hidden',
                overflowY: 'hidden',
            },
        },
        MuiMenu: {
            paper: {
                maxHeight: 300,
            },
        },
    },
    palette: {
        text: {
            secondary: coloresBase.paletaGeneral.gris,
        },
    },
    props: {
        MuiTextField: {
            size: 'small',
        },
    },
};

export const NOMBRE_APLICACION_POR_DEFECTO = 'Smart Suite';
