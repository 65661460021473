export default {
    'sidebar.smartroute': 'Smart Route',

    'sidebar.administracion': 'Administración',

    'sidebar.agencias': 'Agencias',
    'sidebar.aplicaciones': 'Aplicaciones',
    'sidebar.empresas': 'Empresas',
    'sidebar.perfiles': 'Perfiles',
    'sidebar.permisosperfil': 'Permisos perfil',
    'sidebar.usuariospersona': 'Usuarios',
};
