import ModeloEmpresas from "Infotrack@Modelos/empresas";
import { IFiltroEmpresaAplicacion } from "Infotrack@Modelos/empresas/entidades/IFiltroEmpresaAplicacion";

export default class EmpresaFiltroCM {
    private modeloEmpresas: ModeloEmpresas;

    constructor() {
        this.modeloEmpresas = new ModeloEmpresas();
    }

    public async consultarEmpresaAplicacionPorFiltro(filtro: IFiltroEmpresaAplicacion) {
        let { data } = await this.modeloEmpresas.consultarEmpresaAplicacionPorFiltro(filtro);
        return data
    }
}