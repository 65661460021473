import React from 'react';

import { IComponentesAplicacion, IModulo } from '@infotrack/presentacion-transversales/interfacesComunes';

import Agencias from 'Infotrack@Modulos/seguridad/Agencias/Agencias';
import Aplicaciones from 'Infotrack@Modulos/seguridad/Aplicaciones/Aplicaciones';
import Empresas from 'Infotrack@Modulos/seguridad/Empresas/Empresas';
import Perfiles from 'Infotrack@Modulos/seguridad/Perfiles/Perfiles';
import PermisosPerfil from 'Infotrack@Modulos/seguridad/PermisosPerfil/PermisosPerfil';
import UsuariosPersona from 'Infotrack@Modulos/seguridad/UsuariosPersona/UsuariosPersona';
import { ILlavesTemas } from 'Infotrack@Transversales/constantes/temas';
import DatosPersonales from 'Infotrack@Modulos/seguridad/DatosPersonales';
import CatalogoDatos from 'Infotrack@Modulos/administracion/catalogoDatos';

const rutasApp: Array<IModulo<React.ReactNode>> = [
    {
        rutaModulo: 'Administracion',
        rutaSubModuloPorDefecto: 'UsuariosPersona',
        subModulos: [
            {
                componente: Agencias,
                rutaSubModulo: 'Agencias',
            },
            {
                componente: CatalogoDatos,
                rutaSubModulo: 'Catalogo',
            },
            {
                componente: Aplicaciones,
                rutaSubModulo: 'Aplicaciones',
            },
            {
                componente: Empresas,
                rutaSubModulo: 'Empresas',
            },
            {
                componente: Perfiles,
                rutaSubModulo: 'Perfiles',
            },
            {
                componente: PermisosPerfil,
                rutaSubModulo: 'PermisosPerfil',
            },
            {
                componente: UsuariosPersona,
                rutaSubModulo: 'UsuariosPersona',
            },
        ],
        noValidarPermisos: true,
    },

    {

        noValidarPermisos: true,

        rutaModulo: 'Usuario',

        rutaSubModuloPorDefecto: 'Perfil',

        subModulos: [{ componente: DatosPersonales, rutaSubModulo: 'Perfil' }]

    },
];

export const componentesAplicacion: Array<IComponentesAplicacion<keyof ILlavesTemas, React.ReactNode>> = [];

export default rutasApp;
