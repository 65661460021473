import React from 'react';
import { useSelector } from 'react-redux';

import { Agencias as AgenciasModuloBase } from '@infotrack/presentacion-modulosbase';

import { seguridad } from 'Infotrack@Modelos/conexiones';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';

const Agencias = () => {
    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);

    return <AgenciasModuloBase idEmpresa={idEmpresa} seguridad={seguridad} />;
};

export default Agencias;
