export default {
    'sidebar.app': 'Aplicación',
    'barraLateral.label.cerrarSesion': 'Cerrar Sesión',
    'barraLateral.label.lenguajes': 'Lenguajes',
    'barraLateral.label.selector': 'Selector de aplicaciones',
    'barraLateral.title.logo': 'Inicio',
    'barraLateral.title.perfil': 'Perfil',
    'base.alerta.errorConexion': 'Error de conexión, por favor valide su conexión a internet',
    'base.alerta.seleccionarAgencia': 'Seleccione una agencia para continuar',
    'button.paginaNoEncontrada': 'Regresar',
    'lenguajes.label.en': 'Ingles',
    'lenguajes.label.es': 'Español',
    'label.sinRegistros': 'No se encontraron registros',
    'login.inicioSesion': 'Iniciar Sesión',
    'login.placeHolderContrasena': 'Ingrese su contraseña',
    'login.placeHolderUsuario': 'Ingrese su usuario',
    'login.sesion.invalida': 'Su sesión expiro por favor ingrese nuevamente',
    'login.sesion.sesionExpirada': 'Su sesión expiro por inactividad',
    'subtitle.paginaNoEncontrada': 'Página no encontrada',
    'title.paginaNoEncontrada': '404',
    'comunes.alerta.camposIncompletos': 'Por favor ingrese todos los campos requeridos (*)',
    'comunes.alerta.agenciaNoSeleccionada': 'Por favor seleccione una agencia',
    'comunes.alerta.usuarioSinAplicacion': 'Este usuario no cuenta con una aplicación asignada',
    'comunes.alerta.usuarioSinAgencia': 'Este usuario no cuenta con una agencia asignada',
    /* labels tabla */
    'materialTable.emptyDataSourceMessage': 'No se han encontrado registros.',
    'materialTable.saveTooltip': 'Guardar',
    'materialTable.cancelTooltip': 'Cancelar',
    'materialTable.deleteText': '¿Está seguro que desea eliminar este registro?',
    'materialTable.addTooltip': 'Agregar',
    'materialTable.deleteTooltip': 'Eliminar',
    'materialTable.editTooltip': '¿Está seguro que desea editar este registro?',
    'materialTable.actions': 'Acciones',
    'materialTable.groupedBy': 'Agrupados por',
    'materialTable.placeholder': 'Arrastrar encabezados ...',
    'materialTable.firstTooltip': 'Primera página',
    'materialTable.labelDisplayedRows': 'de',
    'materialTable.previousTootip': 'Página anterior',
    'materialTable.nextTooltip': 'Página siguiente',
    'materialTable.labelRowsPerPage': 'Filas por páginas',
    'materialTable.lastTooltip': 'Última página',
    'materialTable.labelRowsSelect': 'Filas',
    'materialTable.addRemoveColumns': 'Mostrar/Ocultar columnas',
    'materialTable.nRowsSelected': 'filas seleccionadas',
    'materialTable.showColumnsTitle': 'Mostrar columnas',
    'materialTable.showColumnsAriaLabel': 'Mostrar columnas',
    'materialTable.exportAriaLabel': 'Exportar',
    'materialTable.exportTitle': 'Exportar',
    'materialTable.exportCSVName': 'Exportar como CSV',
    'materialTable.exportPDFName': 'Exportar como PDF',
    'materialTable.searchTooltip': 'Buscar',
};
