import React from 'react';
import { useSelector } from 'react-redux';

import { Perfiles as PerfilesModuloBase } from '@infotrack/presentacion-modulosbase';

import { seguridad } from 'Infotrack@Modelos/conexiones';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';

const Perfiles = () => {
    const idAplicacion = useSelector(
        (e: IEstadoGlobal) => e.estadoAutenticacion.usuarioAplicacionAgenciaActual!.IdAplicacion
    );
    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);

    return <PerfilesModuloBase idAplicacion={idAplicacion} idEmpresa={idEmpresa} seguridad={seguridad} />;
};

export default Perfiles;
