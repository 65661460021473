import ContenedorPrincipal from 'Infotrack@Principal/contenedorPrincipal';
import { store } from 'Infotrack@Reductores/index';
import React from 'react';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import './app.css';
import ContextoGeneral from './principal/contextoGeneral';

const { NotificationContainer } = require('react-notifications');

const App: React.FunctionComponent = () => (
    <Provider store={store}>
        <ContextoGeneral>
            <Route path="/" component={ContenedorPrincipal} />
            <NotificationContainer />
        </ContextoGeneral>
    </Provider>
);

export default App;
