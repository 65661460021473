import React from 'react';
import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import { useSelector } from 'react-redux';
import { Catalogo } from '@infotrack/presentacion-modulosbase';
import { apiCatalogoToken } from 'Infotrack@Modelos/conexiones';
import { TipoAplicacion } from '@infotrack/presentacion-modulosbase/modulos/Catalogo/interfaces';

const CatalogoDatos = () => {
    const idEmpresa = useSelector((e: IEstadoGlobal) => e.estadoAutenticacion.usuarioInformacion!.IdEmpresa);
    return <Catalogo idEmpresa={idEmpresa} servicioCatalogo={apiCatalogoToken} administrador={true} />;
};

export default CatalogoDatos