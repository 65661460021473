import React from 'react';
import { connect } from 'react-redux';

import AES256 from '@infotrack/utilitarios/aes256';

import { IEstadoGlobal } from 'Infotrack@Reductores/interfacesReductores';
import { llaveGlobal } from 'Infotrack@Transversales/constantes/credenciales';
import { modificarTituloIconoAplicacion } from 'Infotrack@Transversales/utilitarios/funcionesGenerales';

interface IEstadoAProps {
    estadoGlobal: IEstadoGlobal;
}

const PersistorEstado: React.FunctionComponent<IEstadoAProps> = ({ estadoGlobal }) => {
    const cargaInicial = React.useRef(true);

    React.useEffect(() => {
        const {
            estadoAutenticacion: { usuarioAplicacionAgenciaActual },
        } = estadoGlobal;
        if (usuarioAplicacionAgenciaActual)
            modificarTituloIconoAplicacion(usuarioAplicacionAgenciaActual.NombreAplicacion);
    }, []);

    React.useEffect(() => {
        const {
            estadoAutenticacion: { usuarioInformacion },
        } = estadoGlobal;
        if (usuarioInformacion && !cargaInicial.current) {
            const aes256 = new AES256();
            const estadoApp = aes256.cifrar(JSON.stringify(estadoGlobal), llaveGlobal);
            sessionStorage.setItem('estadoApp', estadoApp.toString());
        } else {
            cargaInicial.current = false;
        }
    });
    return null;
};

const estadoAPropiedades = (estadoGlobal: IEstadoGlobal): IEstadoAProps => {
    return { estadoGlobal };
};

export default connect<IEstadoAProps, null, any, IEstadoGlobal>(estadoAPropiedades, null)(PersistorEstado);
